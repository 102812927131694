"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeRelatedAssetIndex = void 0;
const encodeRelatedAssetIndex = (relatedAssetIndex, sortedAssetIds) => {
    const assetIdToAssetIdx = sortedAssetIds.reduce((acc, val, idx) => {
        acc[val] = idx;
        return acc;
    }, {});
    const result = {};
    for (const [assetId, relatedAssets] of Object.entries(relatedAssetIndex)) {
        if (!relatedAssets.length)
            continue;
        const assetIdx = assetIdToAssetIdx[assetId];
        result[assetIdx] = relatedAssets.map(assetId => {
            return assetIdToAssetIdx[assetId];
        });
    }
    return result;
};
exports.encodeRelatedAssetIndex = encodeRelatedAssetIndex;
