"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getChainFlipIdFromAssetId = exports.getChainFlipSwap = exports.calculateChainflipMinPrice = exports.isSupportedAssetId = exports.isSupportedChainId = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("@shapeshiftoss/utils");
const utils_2 = require("../../../utils");
const constants_1 = require("../constants");
const chainflipService_1 = require("./chainflipService");
const isSupportedChainId = (chainId) => {
    return constants_1.ChainflipSupportedChainIds.includes(chainId);
};
exports.isSupportedChainId = isSupportedChainId;
const isSupportedAssetId = (chainId, assetId) => {
    return constants_1.ChainflipSupportedAssetIdsByChainId[chainId].includes(assetId);
};
exports.isSupportedAssetId = isSupportedAssetId;
const calculateChainflipMinPrice = ({ sellAmountIncludingProtocolFeesCryptoBaseUnit, buyAmountAfterFeesCryptoBaseUnit, slippageTolerancePercentageDecimal, sellAsset, buyAsset, }) => {
    const sellAmountCryptoPrecision = (0, utils_1.fromBaseUnit)(sellAmountIncludingProtocolFeesCryptoBaseUnit, sellAsset.precision);
    const buyAmountCryptoPrecision = (0, utils_1.fromBaseUnit)(buyAmountAfterFeesCryptoBaseUnit, buyAsset.precision);
    const estimatedPrice = (0, utils_1.bn)(buyAmountCryptoPrecision).div(sellAmountCryptoPrecision);
    // This is called minimumPrice upstream but this really is a rate, let's not honour confusing terminology
    const minimumRate = estimatedPrice
        .times((0, utils_1.bn)(1).minus((0, utils_1.bnOrZero)(slippageTolerancePercentageDecimal)))
        .toFixed(buyAsset.precision);
    return minimumRate;
};
exports.calculateChainflipMinPrice = calculateChainflipMinPrice;
const getChainFlipSwap = ({ brokerUrl, apiKey, sourceAsset, destinationAsset, destinationAddress, maxBoostFee = 0, minimumPrice, refundAddress, retryDurationInBlocks = 150, commissionBps, numberOfChunks, chunkIntervalBlocks = 2, }) => {
    let swapUrl = `${brokerUrl}/swap` +
        `?apiKey=${apiKey}` +
        `&sourceAsset=${sourceAsset}` +
        `&destinationAsset=${destinationAsset}` +
        `&destinationAddress=${destinationAddress}` +
        `&boostFee=${maxBoostFee}` +
        `&minimumPrice=${minimumPrice}` +
        `&refundAddress=${refundAddress}` +
        `&retryDurationInBlocks=${retryDurationInBlocks}` +
        `&commissionBps=${commissionBps}`;
    if (numberOfChunks && chunkIntervalBlocks) {
        swapUrl += `&numberOfChunks=${numberOfChunks}`;
        swapUrl += `&chunkIntervalBlocks=${chunkIntervalBlocks}`;
    }
    return chainflipService_1.chainflipService.get(swapUrl);
};
exports.getChainFlipSwap = getChainFlipSwap;
const fetchChainFlipAssets = async ({ brokerUrl, }) => {
    const result = await chainflipService_1.chainflipService.get(`${brokerUrl}/assets`);
    if (result.isErr())
        throw result.unwrapErr();
    const { data } = result.unwrap();
    return data.assets;
};
const getChainFlipIdFromAssetId = async ({ assetId, brokerUrl, }) => {
    const chainflipAssets = await fetchChainFlipAssets({
        brokerUrl,
    });
    const { assetReference, chainId } = (0, caip_1.fromAssetId)(assetId);
    const _isToken = (0, utils_2.isToken)(assetId);
    const chainflipAsset = chainflipAssets.find(asset => {
        const isCorrectNetwork = asset.network === constants_1.chainIdToChainflipNetwork[chainId];
        if (!isCorrectNetwork)
            return false;
        if (_isToken) {
            return asset.contractAddress?.toLowerCase() === assetReference.toLowerCase();
        }
        return !asset.contractAddress;
    });
    if (!chainflipAsset)
        throw new Error('Asset not found');
    return chainflipAsset.id;
};
exports.getChainFlipIdFromAssetId = getChainFlipIdFromAssetId;
