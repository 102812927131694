"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeRelatedAssetIndex = void 0;
const decodeRelatedAssetIndex = (encodedRelatedAssetIndex, sortedAssetIds) => {
    const result = {};
    for (let assetIdx = 0; assetIdx < sortedAssetIds.length; assetIdx++) {
        const assetId = sortedAssetIds[assetIdx];
        if (!encodedRelatedAssetIndex[assetIdx])
            continue;
        result[assetId] = encodedRelatedAssetIndex[assetIdx].map(innerAssetIdx => sortedAssetIds[innerAssetIdx]);
    }
    return result;
};
exports.decodeRelatedAssetIndex = decodeRelatedAssetIndex;
