"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CowNetwork = exports.OrderStatus = exports.CompetitionOrderStatusType = exports.OrderError = exports.PriceQuality = exports.OrderQuoteSideKindBuy = exports.OrderQuoteSideKindSell = exports.PlacementError = exports.OrderClass = exports.EcdsaSigningScheme = exports.SigningScheme = exports.BuyTokenDestination = exports.SellTokenSource = exports.OrderKind = exports.TypedDataPrimaryType = void 0;
// The following are lifted directly out of the cowprotocol source code because ethers version conflicts prevent us importing the SDK directly.
// https://github.dev/cowprotocol/cow-sdk/blob/main/src/order-book/generated/models/
var TypedDataPrimaryType;
(function (TypedDataPrimaryType) {
    TypedDataPrimaryType["Order"] = "Order";
    TypedDataPrimaryType["OrderCancellations"] = "OrderCancellations";
})(TypedDataPrimaryType || (exports.TypedDataPrimaryType = TypedDataPrimaryType = {}));
var OrderKind;
(function (OrderKind) {
    OrderKind["BUY"] = "buy";
    OrderKind["SELL"] = "sell";
})(OrderKind || (exports.OrderKind = OrderKind = {}));
var SellTokenSource;
(function (SellTokenSource) {
    SellTokenSource["ERC20"] = "erc20";
    SellTokenSource["INTERNAL"] = "internal";
    SellTokenSource["EXTERNAL"] = "external";
})(SellTokenSource || (exports.SellTokenSource = SellTokenSource = {}));
var BuyTokenDestination;
(function (BuyTokenDestination) {
    BuyTokenDestination["ERC20"] = "erc20";
    BuyTokenDestination["INTERNAL"] = "internal";
})(BuyTokenDestination || (exports.BuyTokenDestination = BuyTokenDestination = {}));
var SigningScheme;
(function (SigningScheme) {
    SigningScheme["EIP712"] = "eip712";
    SigningScheme["ETHSIGN"] = "ethsign";
    SigningScheme["PRESIGN"] = "presign";
    SigningScheme["EIP1271"] = "eip1271";
})(SigningScheme || (exports.SigningScheme = SigningScheme = {}));
var EcdsaSigningScheme;
(function (EcdsaSigningScheme) {
    EcdsaSigningScheme["EIP712"] = "eip712";
    EcdsaSigningScheme["ETHSIGN"] = "ethsign";
})(EcdsaSigningScheme || (exports.EcdsaSigningScheme = EcdsaSigningScheme = {}));
var OrderClass;
(function (OrderClass) {
    OrderClass["MARKET"] = "market";
    OrderClass["LIMIT"] = "limit";
    OrderClass["LIQUIDITY"] = "liquidity";
})(OrderClass || (exports.OrderClass = OrderClass = {}));
var PlacementError;
(function (PlacementError) {
    PlacementError["QUOTE_NOT_FOUND"] = "QuoteNotFound";
    PlacementError["VALID_TO_TOO_FAR_IN_FUTURE"] = "ValidToTooFarInFuture";
    PlacementError["PRE_VALIDATION_ERROR"] = "PreValidationError";
})(PlacementError || (exports.PlacementError = PlacementError = {}));
var OrderQuoteSideKindSell;
(function (OrderQuoteSideKindSell) {
    OrderQuoteSideKindSell["SELL"] = "sell";
})(OrderQuoteSideKindSell || (exports.OrderQuoteSideKindSell = OrderQuoteSideKindSell = {}));
var OrderQuoteSideKindBuy;
(function (OrderQuoteSideKindBuy) {
    OrderQuoteSideKindBuy["BUY"] = "buy";
})(OrderQuoteSideKindBuy || (exports.OrderQuoteSideKindBuy = OrderQuoteSideKindBuy = {}));
var PriceQuality;
(function (PriceQuality) {
    PriceQuality["FAST"] = "fast";
    PriceQuality["OPTIMAL"] = "optimal";
    PriceQuality["VERIFIED"] = "verified";
})(PriceQuality || (exports.PriceQuality = PriceQuality = {}));
var OrderError;
(function (OrderError) {
    OrderError["DUPLICATED_ORDER"] = "DuplicatedOrder";
    OrderError["QUOTE_NOT_FOUND"] = "QuoteNotFound";
    OrderError["QUOTE_NOT_VERIFIED"] = "QuoteNotVerified";
    OrderError["INVALID_QUOTE"] = "InvalidQuote";
    OrderError["MISSING_FROM"] = "MissingFrom";
    OrderError["WRONG_OWNER"] = "WrongOwner";
    OrderError["INVALID_EIP1271SIGNATURE"] = "InvalidEip1271Signature";
    OrderError["INSUFFICIENT_BALANCE"] = "InsufficientBalance";
    OrderError["INSUFFICIENT_ALLOWANCE"] = "InsufficientAllowance";
    OrderError["INVALID_SIGNATURE"] = "InvalidSignature";
    OrderError["SELL_AMOUNT_OVERFLOW"] = "SellAmountOverflow";
    OrderError["TRANSFER_SIMULATION_FAILED"] = "TransferSimulationFailed";
    OrderError["ZERO_AMOUNT"] = "ZeroAmount";
    OrderError["INCOMPATIBLE_SIGNING_SCHEME"] = "IncompatibleSigningScheme";
    OrderError["TOO_MANY_LIMIT_ORDERS"] = "TooManyLimitOrders";
    OrderError["TOO_MUCH_GAS"] = "TooMuchGas";
    OrderError["UNSUPPORTED_BUY_TOKEN_DESTINATION"] = "UnsupportedBuyTokenDestination";
    OrderError["UNSUPPORTED_SELL_TOKEN_SOURCE"] = "UnsupportedSellTokenSource";
    OrderError["UNSUPPORTED_ORDER_TYPE"] = "UnsupportedOrderType";
    OrderError["INSUFFICIENT_VALID_TO"] = "InsufficientValidTo";
    OrderError["EXCESSIVE_VALID_TO"] = "ExcessiveValidTo";
    OrderError["INVALID_NATIVE_SELL_TOKEN"] = "InvalidNativeSellToken";
    OrderError["SAME_BUY_AND_SELL_TOKEN"] = "SameBuyAndSellToken";
    OrderError["UNSUPPORTED_TOKEN"] = "UnsupportedToken";
    OrderError["INVALID_APP_DATA"] = "InvalidAppData";
    OrderError["APP_DATA_HASH_MISMATCH"] = "AppDataHashMismatch";
    OrderError["APPDATA_FROM_MISMATCH"] = "AppdataFromMismatch";
    // Not documented in API docs, but exists.
    // Most likely non-exhaustive, see https://github.com/cowprotocol/contracts/blob/aaffdc55b2a13738b7c32de96f487d3eb5b4f8c6/src/ts/api.ts#L110
    OrderError["SELL_AMOUNT_DOES_NOT_COVER_FEE"] = "SellAmountDoesNotCoverFee";
    OrderError["NO_LIQUIDITY"] = "NoLiquidity";
})(OrderError || (exports.OrderError = OrderError = {}));
var CompetitionOrderStatusType;
(function (CompetitionOrderStatusType) {
    CompetitionOrderStatusType["OPEN"] = "open";
    CompetitionOrderStatusType["SCHEDULED"] = "scheduled";
    CompetitionOrderStatusType["ACTIVE"] = "active";
    CompetitionOrderStatusType["SOLVED"] = "solved";
    CompetitionOrderStatusType["EXECUTING"] = "executing";
    CompetitionOrderStatusType["TRADED"] = "traded";
    CompetitionOrderStatusType["CANCELLED"] = "cancelled";
})(CompetitionOrderStatusType || (exports.CompetitionOrderStatusType = CompetitionOrderStatusType = {}));
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["PRESIGNATURE_PENDING"] = "presignaturePending";
    OrderStatus["OPEN"] = "open";
    OrderStatus["FULFILLED"] = "fulfilled";
    OrderStatus["CANCELLED"] = "cancelled";
    OrderStatus["EXPIRED"] = "expired";
})(OrderStatus || (exports.OrderStatus = OrderStatus = {}));
var CowNetwork;
(function (CowNetwork) {
    CowNetwork["Mainnet"] = "mainnet";
    CowNetwork["Xdai"] = "xdai";
    CowNetwork["ArbitrumOne"] = "arbitrum_one";
    CowNetwork["Base"] = "base";
})(CowNetwork || (exports.CowNetwork = CowNetwork = {}));
