"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeAssetData = void 0;
const assertUnreachable_1 = require("../assertUnreachable");
const constants_1 = require("./constants");
const encodeAssetIds = (sortedAssetIds) => {
    const assetIdPrefixes = [];
    const encodedAssetIds = [];
    for (const assetId of sortedAssetIds) {
        // `indexOf` + `substring` faster than `split`
        const colonIndex = assetId.lastIndexOf(':');
        const prefix = assetId.substring(0, colonIndex);
        const assetReference = assetId.substring(colonIndex + 1);
        const prefixIdx = (() => {
            const prefixIdx = assetIdPrefixes.indexOf(prefix);
            if (prefixIdx !== -1) {
                return prefixIdx;
            }
            else {
                assetIdPrefixes.push(prefix);
                return assetIdPrefixes.length - 1;
            }
        })();
        encodedAssetIds.push(`${prefixIdx}:${assetReference}`);
    }
    return {
        assetIdPrefixes,
        encodedAssetIds,
    };
};
const encodeField = (field, assetIdToAssetIdx, asset) => {
    switch (field) {
        case 'assetIdx':
            return assetIdToAssetIdx[asset.assetId];
        case 'name':
            return asset.name;
        case 'precision':
            return asset.precision;
        case 'color':
            return asset.color;
        case 'icon':
            return (asset.icon ? [asset.icon] : asset.icons ?? []);
        case 'symbol':
            return asset.symbol;
        case 'relatedAssetKey':
            return (!asset.relatedAssetKey ? null : assetIdToAssetIdx[asset.relatedAssetKey]);
        case 'isPool':
            return (asset.isPool ? 1 : 0);
        default:
            return (0, assertUnreachable_1.assertUnreachable)(field);
    }
};
const encodeAssetData = (sortedAssetIds, assetsById) => {
    const { assetIdPrefixes, encodedAssetIds } = encodeAssetIds(sortedAssetIds);
    const assetIdToAssetIdx = sortedAssetIds.reduce((acc, val, idx) => {
        acc[val] = idx;
        return acc;
    }, {});
    const encodedAssets = [];
    for (let i = 0; i < encodedAssetIds.length; i++) {
        const assetId = sortedAssetIds[i];
        const asset = assetsById[assetId];
        const record = constants_1.FIELDS.map(field => encodeField(field, assetIdToAssetIdx, asset));
        encodedAssets.push(record);
    }
    return {
        assetIdPrefixes,
        encodedAssetIds,
        encodedAssets,
    };
};
exports.encodeAssetData = encodeAssetData;
