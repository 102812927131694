"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arbitrumBridgeApi = exports.getParentToChildMessageDataFromParentTxHash = void 0;
const sdk_1 = require("@arbitrum/sdk");
const caip_1 = require("@shapeshiftoss/caip");
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const contracts_1 = require("@shapeshiftoss/contracts");
const types_1 = require("@shapeshiftoss/types");
const unchained_client_1 = require("@shapeshiftoss/unchained-client");
const utils_1 = require("../../utils");
const getTradeQuote_1 = require("./getTradeQuote/getTradeQuote");
const getTradeRate_1 = require("./getTradeRate/getTradeRate");
const fetchArbitrumBridgeSwap_1 = require("./utils/fetchArbitrumBridgeSwap");
const helpers_1 = require("./utils/helpers");
const tradeQuoteMetadata = new Map();
// https://github.com/OffchainLabs/arbitrum-token-bridge/blob/d17c88ef3eef3f4ffc61a04d34d50406039f045d/packages/arb-token-bridge-ui/src/util/deposits/helpers.ts#L268
const getParentToChildMessageDataFromParentTxHash = async ({ depositTxId, parentProvider, childProvider, isClassic, // optional: if we already know if tx is classic (eg. through subgraph) then no need to re-check in this fn
 }) => {
    // fetch Parent transaction receipt
    const depositTxReceipt = await parentProvider.getTransactionReceipt(depositTxId);
    if (!depositTxReceipt)
        return;
    const parentTxReceipt = new sdk_1.ParentTransactionReceipt(depositTxReceipt);
    // classic (pre-nitro) handling
    const getClassicDepositMessage = async () => {
        const [parentToChildMsg] = await parentTxReceipt.getParentToChildMessagesClassic(childProvider);
        return {
            isClassic: true,
            parentToChildMsg,
        };
    };
    // post-nitro handling
    const getNitroDepositMessage = async () => {
        const [parentToChildMsg] = await parentTxReceipt.getParentToChildMessages(childProvider);
        return {
            isClassic: false,
            parentToChildMsg,
        };
    };
    // if it is unknown whether the transaction isClassic or not, fetch the result
    const safeIsClassic = isClassic ?? (await parentTxReceipt.isClassic(childProvider));
    if (safeIsClassic) {
        // classic (pre-nitro) deposit - both eth + token
        return getClassicDepositMessage();
    }
    // post-nitro deposit - both eth + token
    return getNitroDepositMessage();
};
exports.getParentToChildMessageDataFromParentTxHash = getParentToChildMessageDataFromParentTxHash;
exports.arbitrumBridgeApi = {
    getTradeQuote: async (input, deps) => {
        const tradeQuoteResult = await (0, getTradeQuote_1.getTradeQuote)(input, deps);
        return tradeQuoteResult.map(tradeQuote => {
            const id = tradeQuote.id;
            const firstHop = (0, utils_1.getHopByIndex)(tradeQuote, 0);
            tradeQuoteMetadata.set(id, {
                sellAssetId: firstHop.sellAsset.assetId,
                chainId: firstHop.sellAsset.chainId,
            });
            return [tradeQuote];
        });
    },
    getTradeRate: async (input, deps) => {
        const tradeRateResult = await (0, getTradeRate_1.getTradeRate)(input, deps);
        return tradeRateResult.map(tradeQuote => {
            const id = tradeQuote.id;
            const firstHop = (0, utils_1.getHopByIndex)(tradeQuote, 0);
            tradeQuoteMetadata.set(id, {
                sellAssetId: firstHop.sellAsset.assetId,
                chainId: firstHop.sellAsset.chainId,
            });
            return [tradeQuote];
        });
    },
    getUnsignedEvmTransaction: async ({ chainId, from, stepIndex, tradeQuote, supportsEIP1559, assertGetEvmChainAdapter, }) => {
        const step = (0, utils_1.getHopByIndex)(tradeQuote, stepIndex);
        if (!step)
            throw new Error(`No hop found for stepIndex ${stepIndex}`);
        const { buyAsset, sellAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit } = step;
        if (!(0, utils_1.isExecutableTradeQuote)(tradeQuote))
            throw new Error('Cannot execute a trade rate');
        const { receiveAddress } = tradeQuote;
        await (0, helpers_1.assertValidTrade)({ buyAsset, sellAsset });
        const swap = await (0, fetchArbitrumBridgeSwap_1.fetchArbitrumBridgeQuote)({
            chainId,
            supportsEIP1559,
            buyAsset,
            receiveAddress,
            sellAmountIncludingProtocolFeesCryptoBaseUnit,
            sellAsset,
            sendAddress: from,
            assertGetEvmChainAdapter,
        });
        const { request } = swap;
        if (!request)
            throw new Error('No request data found');
        const { txRequest: { data, value, to }, } = request;
        const feeData = await chain_adapters_1.evm.getFees({
            adapter: assertGetEvmChainAdapter(chainId),
            data: data.toString(),
            to,
            value: value.toString(),
            from,
            supportsEIP1559,
        });
        return {
            chainId: Number((0, caip_1.fromChainId)(chainId).chainReference),
            data: data.toString(),
            from,
            to,
            value: value.toString(),
            ...feeData,
        };
    },
    getEvmTransactionFees: async ({ chainId, from, stepIndex, tradeQuote, supportsEIP1559, assertGetEvmChainAdapter, }) => {
        const step = (0, utils_1.getHopByIndex)(tradeQuote, stepIndex);
        if (!step)
            throw new Error(`No hop found for stepIndex ${stepIndex}`);
        const { buyAsset, sellAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit } = step;
        if (!(0, utils_1.isExecutableTradeQuote)(tradeQuote))
            throw new Error('Cannot execute a trade rate');
        const { receiveAddress } = tradeQuote;
        const assertion = await (0, helpers_1.assertValidTrade)({ buyAsset, sellAsset });
        if (assertion.isErr())
            throw new Error(assertion.unwrapErr().message);
        const swap = await (0, fetchArbitrumBridgeSwap_1.fetchArbitrumBridgeQuote)({
            chainId,
            supportsEIP1559,
            buyAsset,
            receiveAddress,
            sellAmountIncludingProtocolFeesCryptoBaseUnit,
            sellAsset,
            sendAddress: from,
            assertGetEvmChainAdapter,
        });
        const { request } = swap;
        if (!request)
            throw new Error('No request data found');
        const { txRequest: { data, value, to }, } = request;
        const { networkFeeCryptoBaseUnit } = await chain_adapters_1.evm.getFees({
            adapter: assertGetEvmChainAdapter(chainId),
            data: data.toString(),
            to,
            value: value.toString(),
            from,
            supportsEIP1559,
        });
        return networkFeeCryptoBaseUnit;
    },
    checkTradeStatus: async ({ txHash, chainId, assertGetEvmChainAdapter, accountId, fetchIsSmartContractAddressQuery, }) => {
        const swapTxStatus = await (0, utils_1.checkEvmSwapStatus)({
            txHash,
            chainId,
            assertGetEvmChainAdapter,
            accountId,
            fetchIsSmartContractAddressQuery,
        });
        const isWithdraw = chainId === caip_1.arbitrumChainId;
        if (isWithdraw) {
            // We don't want to be polling for 7 days for Arb Child -> Parent, that's not very realistic for users.
            // We simply return success when the Child Tx is confirmed, meaning the trade will show "complete" almost instantly
            // and will handle the whole 7 days thing (that the user should've already been warned about with an ack before previewing)
            // at confirm step
            return {
                ...swapTxStatus,
                // Note, we don't care about the buyTxHash here, since it will be available... in 7 days.
                buyTxHash: undefined,
            };
        }
        if (swapTxStatus.status === unchained_client_1.TxStatus.Pending || swapTxStatus.status === unchained_client_1.TxStatus.Unknown) {
            return {
                status: unchained_client_1.TxStatus.Pending,
                buyTxHash: undefined,
                message: 'Parent Tx Pending',
            };
        }
        const parentProvider = (0, contracts_1.getEthersV5Provider)(types_1.KnownChainIds.EthereumMainnet);
        const childProvider = (0, contracts_1.getEthersV5Provider)(types_1.KnownChainIds.ArbitrumMainnet);
        const maybeParentToChildMessageData = await (0, exports.getParentToChildMessageDataFromParentTxHash)({
            depositTxId: txHash,
            parentProvider,
            childProvider,
        });
        const maybeParentToChildMsg = maybeParentToChildMessageData?.parentToChildMsg;
        const maybeBuyTxHash = await (async () => {
            if (!maybeParentToChildMsg)
                return;
            if (maybeParentToChildMessageData?.isClassic) {
                const msg = maybeParentToChildMsg;
                const receipt = await msg.getRetryableCreationReceipt();
                if (receipt?.status !== sdk_1.ParentToChildMessageStatus.REDEEMED)
                    return;
                return receipt.transactionHash;
            }
            else {
                const msg = maybeParentToChildMsg;
                const successfulRedeem = await msg.getSuccessfulRedeem();
                if (successfulRedeem.status !== sdk_1.ParentToChildMessageStatus.REDEEMED)
                    return;
                return successfulRedeem.childTxReceipt.transactionHash;
            }
        })();
        if (!maybeBuyTxHash) {
            return {
                status: unchained_client_1.TxStatus.Pending,
                buyTxHash: maybeBuyTxHash,
                message: 'Parent Tx confirmed, waiting for Child',
            };
        }
        const childTxStatus = await (0, utils_1.checkEvmSwapStatus)({
            txHash: maybeBuyTxHash,
            chainId: caip_1.arbitrumChainId,
            assertGetEvmChainAdapter,
            accountId,
            fetchIsSmartContractAddressQuery,
        });
        // i.e Unknown is perfectly valid since ETH deposits Child Txids are available immediately deterministically, but will only be in the mempool after ~10mn
        if (childTxStatus.status === unchained_client_1.TxStatus.Pending || childTxStatus.status === unchained_client_1.TxStatus.Unknown) {
            return {
                status: unchained_client_1.TxStatus.Pending,
                buyTxHash: maybeBuyTxHash,
                message: 'Child Tx Pending',
            };
        }
        return {
            status: unchained_client_1.TxStatus.Confirmed,
            buyTxHash: maybeBuyTxHash,
            message: undefined,
        };
    },
};
