"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIELDS = void 0;
exports.FIELDS = [
    'assetIdx',
    'name',
    'precision',
    'color',
    'icon',
    'symbol',
    'relatedAssetKey',
    'isPool',
];
