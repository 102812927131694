"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jupiterSwapper = void 0;
const __1 = require("../..");
const errorPatterns_1 = require("./errorPatterns");
const constants_1 = require("./utils/constants");
exports.jupiterSwapper = {
    executeSolanaTransaction: async (...args) => {
        try {
            const txid = await (0, __1.executeSolanaTransaction)(...args);
            return txid;
        }
        catch (e) {
            if (e instanceof Error) {
                const errorMessage = e.message;
                const swapperErrorType = Object.keys(errorPatterns_1.JUPITER_ERRORS).reduce((acc, errorPattern) => {
                    if (errorMessage.includes(errorPattern)) {
                        acc = errorPatterns_1.JUPITER_ERRORS[errorPattern];
                    }
                    return acc;
                }, undefined);
                if (swapperErrorType)
                    throw new errorPatterns_1.SolanaLogsError(swapperErrorType);
            }
            throw e;
        }
    },
    filterAssetIdsBySellable: (assets) => {
        return Promise.resolve(assets
            .filter(asset => {
            const { chainId } = asset;
            return constants_1.jupiterSupportedChainIds.includes(chainId);
        })
            .map(asset => asset.assetId));
    },
    filterBuyAssetsBySellAssetId: (input) => {
        return Promise.resolve(input.assets
            .filter(asset => {
            const { chainId } = asset;
            return constants_1.jupiterSupportedChainIds.includes(chainId);
        })
            .map(asset => asset.assetId));
    },
};
