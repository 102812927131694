"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBaseAsset = void 0;
const types_1 = require("@shapeshiftoss/types");
const assertUnreachable_1 = require("../assertUnreachable");
const baseAssets_1 = require("./baseAssets");
const getBaseAsset = (chainId) => {
    const knownChainId = chainId;
    switch (knownChainId) {
        case types_1.KnownChainIds.EthereumMainnet:
            return baseAssets_1.ethereum;
        case types_1.KnownChainIds.AvalancheMainnet:
            return baseAssets_1.avax;
        case types_1.KnownChainIds.OptimismMainnet:
            return baseAssets_1.optimism;
        case types_1.KnownChainIds.BnbSmartChainMainnet:
            return baseAssets_1.bnbsmartchain;
        case types_1.KnownChainIds.PolygonMainnet:
            return baseAssets_1.polygon;
        case types_1.KnownChainIds.GnosisMainnet:
            return baseAssets_1.gnosis;
        case types_1.KnownChainIds.ArbitrumMainnet:
            return baseAssets_1.arbitrum;
        case types_1.KnownChainIds.ArbitrumNovaMainnet:
            return baseAssets_1.arbitrumNova;
        case types_1.KnownChainIds.BaseMainnet:
            return baseAssets_1.base;
        case types_1.KnownChainIds.SolanaMainnet:
            return baseAssets_1.solana;
        case types_1.KnownChainIds.BitcoinMainnet:
            return baseAssets_1.bitcoin;
        case types_1.KnownChainIds.BitcoinCashMainnet:
            return baseAssets_1.bitcoincash;
        case types_1.KnownChainIds.DogecoinMainnet:
            return baseAssets_1.dogecoin;
        case types_1.KnownChainIds.LitecoinMainnet:
            return baseAssets_1.litecoin;
        case types_1.KnownChainIds.CosmosMainnet:
            return baseAssets_1.atom;
        case types_1.KnownChainIds.ThorchainMainnet:
            return baseAssets_1.thorchain;
        default:
            return (0, assertUnreachable_1.assertUnreachable)(knownChainId);
    }
};
exports.getBaseAsset = getBaseAsset;
