"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTradeQuote = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const monads_1 = require("@sniptt/monads");
const types_1 = require("../../../types");
const utils_1 = require("../../../utils");
const constants_1 = require("../constants");
const getQuoteOrRate_1 = require("../utils/getQuoteOrRate");
const helpers_1 = require("../utils/helpers");
const getTradeQuote = async (input, deps) => {
    const { sellAsset, buyAsset, accountNumber, sendAddress, receiveAddress, sellAmountIncludingProtocolFeesCryptoBaseUnit: sellAmount, } = input;
    if (accountNumber === undefined) {
        return (0, monads_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: `accountNumber is required`,
            code: types_1.TradeQuoteError.UnknownError,
        }));
    }
    if (sendAddress === undefined) {
        return (0, monads_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: `sendAddress is required`,
            code: types_1.TradeQuoteError.UnknownError,
        }));
    }
    if (receiveAddress === undefined) {
        return (0, monads_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: `receiveAddress is required`,
            code: types_1.TradeQuoteError.UnknownError,
        }));
    }
    const maybeTradeQuotes = await (0, getQuoteOrRate_1.getQuoteOrRate)(input, deps);
    if (maybeTradeQuotes.isErr())
        return (0, monads_1.Err)(maybeTradeQuotes.unwrapErr());
    const brokerUrl = deps.config.REACT_APP_CHAINFLIP_API_URL;
    const apiKey = deps.config.REACT_APP_CHAINFLIP_API_KEY;
    const tradeQuotes = maybeTradeQuotes.unwrap();
    // We need to open a deposit channel at this point to attach the swap id to the quote,
    // in order to properly fetch the streaming status later
    for (const tradeQuote of tradeQuotes) {
        for (const step of tradeQuote.steps) {
            const sourceAsset = await (0, helpers_1.getChainFlipIdFromAssetId)({
                assetId: sellAsset.assetId,
                brokerUrl,
            });
            const destinationAsset = await (0, helpers_1.getChainFlipIdFromAssetId)({
                assetId: buyAsset.assetId,
                brokerUrl,
            });
            const minimumPrice = (0, helpers_1.calculateChainflipMinPrice)({
                slippageTolerancePercentageDecimal: tradeQuote.slippageTolerancePercentageDecimal,
                sellAsset,
                buyAsset,
                buyAmountAfterFeesCryptoBaseUnit: step.buyAmountAfterFeesCryptoBaseUnit,
                sellAmountIncludingProtocolFeesCryptoBaseUnit: step.sellAmountIncludingProtocolFeesCryptoBaseUnit,
            });
            let serviceCommission = parseInt(tradeQuote.affiliateBps) - constants_1.CHAINFLIP_BAAS_COMMISSION;
            if (serviceCommission < 0)
                serviceCommission = 0;
            const maybeSwapResponse = await (0, helpers_1.getChainFlipSwap)({
                brokerUrl,
                apiKey,
                sourceAsset,
                minimumPrice,
                destinationAsset,
                destinationAddress: input.receiveAddress,
                refundAddress: input.sendAddress,
                maxBoostFee: step.chainflipSpecific?.chainflipMaxBoostFee,
                numberOfChunks: step.chainflipSpecific?.chainflipNumberOfChunks,
                chunkIntervalBlocks: step.chainflipSpecific?.chainflipChunkIntervalBlocks,
                commissionBps: serviceCommission,
            });
            if (maybeSwapResponse.isErr()) {
                const error = maybeSwapResponse.unwrapErr();
                const cause = error.cause;
                throw Error(cause.response.data.detail);
            }
            const { data: swapResponse } = maybeSwapResponse.unwrap();
            if (!swapResponse.id)
                throw Error('Missing Swap Id');
            if (!swapResponse.address)
                throw Error('Missing Deposit Channel');
            const depositAddress = swapResponse.address;
            const getFeeData = async () => {
                const { chainNamespace } = (0, caip_1.fromAssetId)(sellAsset.assetId);
                // We faked feeData for Solana with a self-send during rates, we can now properly do it on quote time
                switch (chainNamespace) {
                    case caip_1.CHAIN_NAMESPACE.Solana: {
                        const sellAdapter = deps.assertGetSolanaChainAdapter(sellAsset.chainId);
                        const getFeeDataInput = {
                            to: depositAddress,
                            value: sellAmount,
                            chainSpecific: {
                                from: input.sendAddress,
                                tokenId: sellAsset.assetId === caip_1.solAssetId
                                    ? undefined
                                    : (0, caip_1.fromAssetId)(sellAsset.assetId).assetReference,
                            },
                        };
                        const { fast } = await sellAdapter.getFeeData(getFeeDataInput);
                        return {
                            protocolFees: step.feeData.protocolFees,
                            networkFeeCryptoBaseUnit: fast.txFee,
                        };
                    }
                    default:
                        return step.feeData;
                }
            };
            if (!step.chainflipSpecific)
                step.chainflipSpecific = {
                    chainflipSwapId: swapResponse.id,
                    chainflipDepositAddress: depositAddress,
                };
            step.chainflipSpecific.chainflipSwapId = swapResponse.id;
            step.chainflipSpecific.chainflipDepositAddress = swapResponse.address;
            step.feeData = await getFeeData();
        }
    }
    const quotesResult = (0, monads_1.Ok)(tradeQuotes);
    return quotesResult.map(quotes => quotes.map(quote => ({
        ...quote,
        quoteOrRate: 'quote',
        receiveAddress: receiveAddress,
        steps: quote.steps.map(step => step),
    })));
};
exports.getTradeQuote = getTradeQuote;
