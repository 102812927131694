"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cowApi = void 0;
const types_1 = require("@shapeshiftoss/types");
const unchained_client_1 = require("@shapeshiftoss/unchained-client");
const utils_1 = require("@shapeshiftoss/utils");
const uuid_1 = require("uuid");
const constants_1 = require("../../constants");
const cowswap_utils_1 = require("../../cowswap-utils");
const types_2 = require("../../types");
const utils_2 = require("../../utils");
const getCowSwapTradeQuote_1 = require("./getCowSwapTradeQuote/getCowSwapTradeQuote");
const getCowSwapTradeRate_1 = require("./getCowSwapTradeRate/getCowSwapTradeRate");
const cowService_1 = require("./utils/cowService");
const helpers_1 = require("./utils/helpers/helpers");
const tradeQuoteMetadata = new Map();
exports.cowApi = {
    getTradeQuote: async (input, { config }) => {
        const tradeQuoteResult = await (0, getCowSwapTradeQuote_1.getCowSwapTradeQuote)(input, config);
        return tradeQuoteResult.map(tradeQuote => {
            // A quote always has a first step
            const firstStep = (0, utils_2.getHopByIndex)(tradeQuote, 0);
            const id = (0, uuid_1.v4)();
            tradeQuoteMetadata.set(id, { chainId: firstStep.sellAsset.chainId });
            return [tradeQuote];
        });
    },
    getTradeRate: async (input, { config }) => {
        const tradeRateResult = await (0, getCowSwapTradeRate_1.getCowSwapTradeRate)(input, config);
        return tradeRateResult.map(tradeRate => {
            // A rate always has a first step
            const firstStep = (0, utils_2.getHopByIndex)(tradeRate, 0);
            const id = (0, uuid_1.v4)();
            tradeQuoteMetadata.set(id, { chainId: firstStep.sellAsset.chainId });
            return [tradeRate];
        });
    },
    getUnsignedEvmMessage: async ({ tradeQuote, stepIndex, chainId, }) => {
        const hop = (0, utils_2.getHopByIndex)(tradeQuote, stepIndex);
        if (!hop)
            throw new Error(`No hop found for stepIndex ${stepIndex}`);
        const { sellAsset } = hop;
        const { slippageTolerancePercentageDecimal = (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_2.SwapperName.CowSwap), } = tradeQuote;
        if (!(0, utils_2.isExecutableTradeQuote)(tradeQuote))
            throw new Error('Unable to execute trade');
        // Check the chainId is supported for paranoia
        (0, cowswap_utils_1.assertGetCowNetwork)(sellAsset.chainId);
        const affiliateAppDataFragment = (0, cowswap_utils_1.getAffiliateAppDataFragmentByChainId)({
            affiliateBps: tradeQuote.affiliateBps,
            chainId: sellAsset.chainId,
        });
        const { appDataHash } = await (0, cowswap_utils_1.getFullAppData)(slippageTolerancePercentageDecimal, affiliateAppDataFragment, 'market');
        const { cowswapQuoteResponse } = hop;
        if (!cowswapQuoteResponse)
            throw new Error('CowSwap quote data is required');
        const { id, quote } = cowswapQuoteResponse;
        // Note: While CowSwap returns us a quote, and we have slippageBips in the appData, this isn't enough.
        // For the slippage actually to be enforced, the final message to be signed needs to have slippage deducted.
        // Failure to do so means orders may take forever to be filled, or never be filled at all.
        const quoteBuyAmount = quote.buyAmount;
        // Remove affiliate fees off the buyAmount to get the amount after affiliate fees, but before slippage bips
        const buyAmountAfterAffiliateFeesCryptoBaseUnit = (0, helpers_1.deductAffiliateFeesFromAmount)({
            amount: quoteBuyAmount,
            affiliateBps: tradeQuote.affiliateBps,
        });
        const buyAmountAfterAffiliateFeesAndSlippageCryptoBaseUnit = (0, helpers_1.deductSlippageFromAmount)({
            amount: buyAmountAfterAffiliateFeesCryptoBaseUnit,
            slippageTolerancePercentageDecimal,
        }).toFixed(0);
        // CoW API and flow is weird - same idea as the mutation above, we need to incorporate protocol fees into the order
        // This was previously working as-is with fees being deducted from the sell amount at protocol-level, but we now we need to add them into the order
        // In other words, this means what was previously CoW being "feeless" as far as we're concerned
        // i.e no additional fees to account for when doing balance checks, no longer holds true
        //
        // This also makes CoW the first and currently *only* swapper where max token swaps aren't full balance
        const sellAmountPlusProtocolFees = (0, utils_1.bn)(quote.sellAmount).plus(quote.feeAmount);
        const orderToSign = {
            ...quote,
            // Another mutation from the original quote to go around the fact that CoW API flow is weird
            // they return us a quote with fees, but we have to zero them out when sending the order
            feeAmount: '0',
            buyAmount: buyAmountAfterAffiliateFeesAndSlippageCryptoBaseUnit,
            sellAmount: sellAmountPlusProtocolFees.toFixed(0),
            // from,
            sellTokenBalance: types_1.SellTokenSource.ERC20,
            buyTokenBalance: types_1.BuyTokenDestination.ERC20,
            quoteId: id,
            appDataHash,
            signingScheme: types_1.SigningScheme.EIP712,
        };
        return { chainId, orderToSign };
    },
    getEvmTransactionFees: (_args) => {
        // No transaction fees for CoW
        return Promise.resolve('0');
    },
    checkTradeStatus: async ({ txHash, // TODO: this is not a tx hash, its an ID
    chainId, accountId, fetchIsSmartContractAddressQuery, assertGetEvmChainAdapter, config, }) => {
        const maybeSafeTransactionStatus = await (0, utils_2.checkSafeTransactionStatus)({
            txHash,
            chainId,
            assertGetEvmChainAdapter,
            fetchIsSmartContractAddressQuery,
            accountId,
        });
        if (maybeSafeTransactionStatus)
            return maybeSafeTransactionStatus;
        const network = (0, cowswap_utils_1.assertGetCowNetwork)(chainId);
        // with cow we aren't able to get the tx hash until it's already completed, so we must use the
        // order uid to fetch the trades and use their existence as indicating "complete"
        // https://docs.cow.fi/tutorials/how-to-submit-orders-via-the-api/6.-checking-order-status
        const maybeTradesResponse = await cowService_1.cowService.get(`${config.REACT_APP_COWSWAP_BASE_URL}/${network}/api/v1/trades`, { params: { orderUid: txHash } });
        if (maybeTradesResponse.isErr())
            throw maybeTradesResponse.unwrapErr();
        const { data: trades } = maybeTradesResponse.unwrap();
        const buyTxHash = trades[0]?.txHash;
        if (buyTxHash) {
            return {
                status: unchained_client_1.TxStatus.Confirmed,
                buyTxHash,
                message: 'fulfilled',
            };
        }
        return {
            status: unchained_client_1.TxStatus.Pending,
            buyTxHash: undefined,
            message: 'open',
        };
    },
};
