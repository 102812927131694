"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isToken2022AccountInfo = void 0;
const isToken2022AccountInfo = (data) => {
    if (!data || Buffer.isBuffer(data))
        return false;
    return ('program' in data &&
        typeof data.program === 'string' &&
        data.program === 'spl-token-2022' &&
        'parsed' in data &&
        'type' in data.parsed &&
        data.parsed.type === 'mint');
};
exports.isToken2022AccountInfo = isToken2022AccountInfo;
