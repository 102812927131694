"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEthersV5Provider = exports.getEthersProvider = exports.rpcUrlByChainId = void 0;
const types_1 = require("@shapeshiftoss/types");
const utils_1 = require("@shapeshiftoss/utils");
const ethers_1 = require("ethers");
const ethers5_1 = require("ethers5");
const rpcUrlByChainId = (chainId) => {
    const url = (() => {
        switch (chainId) {
            case types_1.KnownChainIds.AvalancheMainnet:
                return process.env.REACT_APP_AVALANCHE_NODE_URL;
            case types_1.KnownChainIds.OptimismMainnet:
                return process.env.REACT_APP_OPTIMISM_NODE_URL;
            case types_1.KnownChainIds.BnbSmartChainMainnet:
                return process.env.REACT_APP_BNBSMARTCHAIN_NODE_URL;
            case types_1.KnownChainIds.PolygonMainnet:
                return process.env.REACT_APP_POLYGON_NODE_URL;
            case types_1.KnownChainIds.GnosisMainnet:
                return process.env.REACT_APP_GNOSIS_NODE_URL;
            case types_1.KnownChainIds.EthereumMainnet:
                return process.env.REACT_APP_ETHEREUM_NODE_URL;
            case types_1.KnownChainIds.ArbitrumMainnet:
                return process.env.REACT_APP_ARBITRUM_NODE_URL;
            case types_1.KnownChainIds.ArbitrumNovaMainnet:
                return process.env.REACT_APP_ARBITRUM_NOVA_NODE_URL;
            case types_1.KnownChainIds.BaseMainnet:
                return process.env.REACT_APP_BASE_NODE_URL;
            default:
                return (0, utils_1.assertUnreachable)(chainId);
        }
    })();
    if (!url) {
        throw new Error(`No RPC URL found for chainId ${chainId}`);
    }
    return url;
};
exports.rpcUrlByChainId = rpcUrlByChainId;
const ethersProviders = new Map();
const ethersV5Providers = new Map();
const getEthersProvider = (chainId) => {
    if (!ethersProviders.has(chainId)) {
        const provider = new ethers_1.JsonRpcProvider((0, exports.rpcUrlByChainId)(chainId), undefined, {
            staticNetwork: true,
        });
        ethersProviders.set(chainId, provider);
        return provider;
    }
    else {
        return ethersProviders.get(chainId);
    }
};
exports.getEthersProvider = getEthersProvider;
// For backwards-compatibility for libraries still stuck in v5
const getEthersV5Provider = (chainId = types_1.KnownChainIds.EthereumMainnet) => {
    if (!ethersV5Providers.has(chainId)) {
        const provider = new ethers5_1.ethers.providers.StaticJsonRpcProvider((0, exports.rpcUrlByChainId)(chainId));
        ethersV5Providers.set(chainId, provider);
        return provider;
    }
    else {
        return ethersV5Providers.get(chainId);
    }
};
exports.getEthersV5Provider = getEthersV5Provider;
