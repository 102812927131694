"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSwapInstructions = exports.calculateAccountCreationCosts = exports.getFeeTokenAccountAndInstruction = exports.getJupiterSwapInstructions = exports.getJupiterPrice = exports.isSupportedChainId = void 0;
const anchor_1 = require("@coral-xyz/anchor");
const token_1 = require("@coral-xyz/anchor/dist/cjs/utils/token");
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("@shapeshiftoss/utils");
const web3_js_1 = require("@solana/web3.js");
const referral_1 = require("../idls/referral");
const constants_1 = require("./constants");
const jupiterService_1 = require("./jupiterService");
const isSupportedChainId = (chainId) => {
    return constants_1.jupiterSupportedChainIds.includes(chainId);
};
exports.isSupportedChainId = isSupportedChainId;
const JUPITER_TRANSACTION_MAX_ACCOUNTS = 54;
const getJupiterPrice = ({ apiUrl, sourceAssetAddress, destinationAssetAddress, commissionBps, amount, slippageBps, }) => jupiterService_1.jupiterService.get(`${apiUrl}/quote` +
    `?inputMint=${sourceAssetAddress}` +
    `&outputMint=${destinationAssetAddress}` +
    `&amount=${amount}` +
    (slippageBps ? `&slippageBps=${slippageBps}` : `&dynamicSlippage=true`) +
    `&maxAccounts=${JUPITER_TRANSACTION_MAX_ACCOUNTS}` +
    `&platformFeeBps=${commissionBps}`);
exports.getJupiterPrice = getJupiterPrice;
const getJupiterSwapInstructions = ({ apiUrl, fromAddress, toAddress, rawQuote, feeAccount, }) => jupiterService_1.jupiterService.post(`${apiUrl}/swap-instructions`, {
    userPublicKey: fromAddress,
    destinationTokenAccount: toAddress,
    quoteResponse: rawQuote,
    dynamicComputeUnitLimit: true,
    prioritizationFeeLamports: 'auto',
    feeAccount,
});
exports.getJupiterSwapInstructions = getJupiterSwapInstructions;
const getFeeTokenAccountAndInstruction = async ({ feePayerPubKey, programId, buyAssetReferralPubKey, sellAssetReferralPubKey, buyTokenId, sellTokenId, instructionData, connection, }) => {
    const sellAssetTokenAccount = await connection.getAccountInfo(sellAssetReferralPubKey);
    if (sellAssetTokenAccount)
        return { tokenAccount: sellAssetReferralPubKey };
    const buyAssetTokenAccount = await connection.getAccountInfo(buyAssetReferralPubKey);
    if (buyAssetTokenAccount)
        return { tokenAccount: buyAssetReferralPubKey };
    const buyTokenInfo = await connection.getAccountInfo(new web3_js_1.PublicKey(buyTokenId));
    const sellTokenInfo = await connection.getAccountInfo(new web3_js_1.PublicKey(sellTokenId));
    const isSellTokenToken2022 = sellTokenInfo?.owner.toString() === constants_1.TOKEN_2022_PROGRAM_ID.toString();
    if (buyTokenInfo?.owner.toString() !== token_1.TOKEN_PROGRAM_ID.toString() &&
        sellTokenInfo?.owner.toString() !== token_1.TOKEN_PROGRAM_ID.toString()) {
        return { tokenAccount: undefined, instruction: undefined };
    }
    const project = new web3_js_1.PublicKey(constants_1.JUPITER_REFERALL_FEE_PROJECT_ACCOUNT);
    const referralPubkey = isSellTokenToken2022 ? buyAssetReferralPubKey : sellAssetReferralPubKey;
    return {
        tokenAccount: referralPubkey,
        instruction: new web3_js_1.TransactionInstruction({
            keys: [
                {
                    pubkey: feePayerPubKey,
                    isSigner: true,
                    isWritable: true,
                },
                {
                    pubkey: project,
                    isWritable: false,
                    isSigner: false,
                },
                {
                    pubkey: new web3_js_1.PublicKey(constants_1.SHAPESHIFT_JUPITER_REFERRAL_KEY),
                    isSigner: false,
                    isWritable: false,
                },
                {
                    pubkey: referralPubkey,
                    isSigner: false,
                    isWritable: true,
                },
                {
                    pubkey: new web3_js_1.PublicKey(isSellTokenToken2022 ? buyTokenId : sellTokenId),
                    isSigner: false,
                    isWritable: false,
                },
                {
                    pubkey: web3_js_1.SystemProgram.programId,
                    isSigner: false,
                    isWritable: false,
                },
                { pubkey: token_1.TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
            ],
            data: instructionData,
            programId,
        }),
    };
};
exports.getFeeTokenAccountAndInstruction = getFeeTokenAccountAndInstruction;
const calculateAccountCreationCosts = (instructions) => {
    let totalCost = (0, utils_1.bnOrZero)(0);
    for (const ix of instructions) {
        if (ix.programId.toString() === token_1.ASSOCIATED_PROGRAM_ID.toString()) {
            if (ix.data?.[0] === 1) {
                totalCost = totalCost.plus(constants_1.PDA_ACCOUNT_CREATION_COST);
            }
        }
    }
    return totalCost.toString();
};
exports.calculateAccountCreationCosts = calculateAccountCreationCosts;
const createSwapInstructions = async ({ priceResponse, sendAddress, receiveAddress, affiliateBps, buyAsset, sellAsset, adapter, jupiterUrl, }) => {
    const isCrossAccountTrade = receiveAddress ? receiveAddress !== sendAddress : false;
    const buyAssetAddress = buyAsset.assetId === caip_1.solAssetId
        ? (0, caip_1.fromAssetId)(caip_1.wrappedSolAssetId).assetReference
        : (0, caip_1.fromAssetId)(buyAsset.assetId).assetReference;
    const sellAssetAddress = sellAsset.assetId === caip_1.solAssetId
        ? (0, caip_1.fromAssetId)(caip_1.wrappedSolAssetId).assetReference
        : (0, caip_1.fromAssetId)(sellAsset.assetId).assetReference;
    const contractAddress = buyAsset.assetId === caip_1.solAssetId ? undefined : (0, caip_1.fromAssetId)(buyAsset.assetId).assetReference;
    const { instruction: createTokenAccountInstruction, destinationTokenAccount } = contractAddress && isCrossAccountTrade
        ? await adapter.createAssociatedTokenAccountInstruction({
            from: sendAddress,
            to: receiveAddress,
            tokenId: contractAddress,
        })
        : { instruction: undefined, destinationTokenAccount: undefined };
    const [buyAssetReferralPubKey] = web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from('referral_ata'),
        new web3_js_1.PublicKey(constants_1.SHAPESHIFT_JUPITER_REFERRAL_KEY).toBuffer(),
        new web3_js_1.PublicKey(buyAssetAddress).toBuffer(),
    ], new web3_js_1.PublicKey(constants_1.JUPITER_AFFILIATE_CONTRACT_ADDRESS));
    const [sellAssetReferralPubKey] = web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from('referral_ata'),
        new web3_js_1.PublicKey(constants_1.SHAPESHIFT_JUPITER_REFERRAL_KEY).toBuffer(),
        new web3_js_1.PublicKey(sellAssetAddress).toBuffer(),
    ], new web3_js_1.PublicKey(constants_1.JUPITER_AFFILIATE_CONTRACT_ADDRESS));
    const instructionData = new anchor_1.BorshInstructionCoder(referral_1.referralIdl).encode('initializeReferralTokenAccount', {});
    const { instruction: feeAccountInstruction, tokenAccount } = await (0, exports.getFeeTokenAccountAndInstruction)({
        feePayerPubKey: new web3_js_1.PublicKey(sendAddress),
        buyAssetReferralPubKey,
        sellAssetReferralPubKey,
        programId: new web3_js_1.PublicKey(constants_1.JUPITER_AFFILIATE_CONTRACT_ADDRESS),
        instructionData,
        buyTokenId: buyAssetAddress,
        sellTokenId: sellAssetAddress,
        connection: adapter.getConnection(),
    });
    const maybeSwapResponse = await (0, exports.getJupiterSwapInstructions)({
        apiUrl: jupiterUrl,
        fromAddress: sendAddress,
        toAddress: isCrossAccountTrade ? destinationTokenAccount?.toString() : undefined,
        rawQuote: priceResponse,
        feeAccount: affiliateBps !== '0' && tokenAccount ? tokenAccount.toString() : undefined,
    });
    if (maybeSwapResponse.isErr()) {
        const error = maybeSwapResponse.unwrapErr();
        const cause = error.cause;
        throw Error(cause.response.data.detail);
    }
    const { data: swapResponse } = maybeSwapResponse.unwrap();
    const convertJupiterInstruction = (instruction) => ({
        ...instruction,
        keys: instruction.accounts.map(account => ({
            ...account,
            pubkey: new web3_js_1.PublicKey(account.pubkey),
        })),
        data: Buffer.from(instruction.data, 'base64'),
        programId: new web3_js_1.PublicKey(instruction.programId),
    });
    const instructions = [
        ...swapResponse.setupInstructions.map(convertJupiterInstruction),
        convertJupiterInstruction(swapResponse.swapInstruction),
    ];
    if (feeAccountInstruction && affiliateBps !== '0') {
        instructions.unshift(feeAccountInstruction);
    }
    if (createTokenAccountInstruction) {
        instructions.unshift(createTokenAccountInstruction);
    }
    if (swapResponse.cleanupInstruction) {
        instructions.push(convertJupiterInstruction(swapResponse.cleanupInstruction));
    }
    return { instructions, addressLookupTableAddresses: swapResponse.addressLookupTableAddresses };
};
exports.createSwapInstructions = createSwapInstructions;
