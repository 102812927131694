"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeAssetData = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const lodash_1 = require("lodash");
const assertUnreachable_1 = require("../assertUnreachable");
const constants_1 = require("./constants");
const getBaseAsset_1 = require("./getBaseAsset");
const decodeAssetId = (encodedAssetId, assetIdPrefixes) => {
    // Performance sensitive. `lastIndexOf` + `substring` faster than `split`
    const colonIndex = encodedAssetId.lastIndexOf(':');
    const prefixIdx = Number(encodedAssetId.substring(0, colonIndex));
    const assetReference = encodedAssetId.substring(colonIndex + 1);
    // Performance sensitive. String concatenation faster than template literal
    return assetIdPrefixes[prefixIdx] + ':' + assetReference;
};
const decodeAssetData = (encodedAssetData) => {
    const { assetIdPrefixes, encodedAssetIds, encodedAssets } = encodedAssetData;
    const sortedAssetIds = encodedAssetIds.map(encodedAssetId => decodeAssetId(encodedAssetId, assetIdPrefixes));
    const assetData = encodedAssets.reduce((acc, encodedAsset, idx) => {
        const assetId = sortedAssetIds[idx];
        const { chainId } = (0, caip_1.fromAssetId)(assetId);
        const baseAsset = (0, getBaseAsset_1.getBaseAsset)(chainId);
        // Initialize with default values for the chain
        const asset = Object.assign({
            assetId,
            chainId,
            symbol: '',
            name: '',
            precision: 0,
            color: '',
            icon: undefined,
            explorer: '',
            explorerAddressLink: '',
            explorerTxLink: '',
            relatedAssetKey: null,
        }, (0, lodash_1.pick)(baseAsset, ['explorer', 'explorerAddressLink', 'explorerTxLink']));
        if (assetId === baseAsset?.assetId) {
            asset.networkName = baseAsset.networkName;
            asset.networkIcon = baseAsset.networkIcon;
            asset.networkColor = baseAsset.networkColor;
        }
        // Apply each field's decoded value to the asset
        constants_1.FIELDS.forEach((field, fieldIdx) => {
            const value = encodedAsset[fieldIdx];
            switch (field) {
                case 'icon': {
                    const iconOrIcons = value;
                    if (iconOrIcons.length === 1) {
                        asset.icon = iconOrIcons[0];
                    }
                    else if (iconOrIcons.length > 1) {
                        asset.icons = iconOrIcons;
                    }
                    break;
                }
                case 'relatedAssetKey': {
                    const assetIdx = value;
                    const relatedAssetId = assetIdx === null ? null : sortedAssetIds[assetIdx];
                    // if (relatedAssetId === undefined) throw Error()
                    asset.relatedAssetKey = relatedAssetId;
                    break;
                }
                case 'name':
                    asset.name = value;
                    break;
                case 'precision':
                    asset.precision = value;
                    break;
                case 'color':
                    asset.color = value;
                    break;
                case 'symbol':
                    asset.symbol = value;
                    break;
                case 'isPool': {
                    const isPool = value;
                    if (isPool) {
                        asset.isPool = true;
                    }
                    break;
                }
                case 'assetIdx':
                    // Skip assetIdx as we already have assetId
                    break;
                default:
                    (0, assertUnreachable_1.assertUnreachable)(field);
            }
        });
        acc[assetId] = asset;
        return acc;
    }, {});
    return {
        assetData,
        sortedAssetIds,
    };
};
exports.decodeAssetData = decodeAssetData;
