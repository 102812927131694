"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JUPITER_ERRORS = exports.JUPITER_ERROR_NAME = exports.JUPITER_ERROR_PATTERNS = exports.SolanaLogsError = void 0;
class SolanaLogsError extends Error {
    constructor(name) {
        super(name);
        this.name = name;
    }
}
exports.SolanaLogsError = SolanaLogsError;
var JUPITER_ERROR_PATTERNS;
(function (JUPITER_ERROR_PATTERNS) {
    JUPITER_ERROR_PATTERNS["SLIPPAGE_TOLERANCE_EXCEEDED"] = "SlippageToleranceExceeded";
    JUPITER_ERROR_PATTERNS["JUPITER_SLIPPAGE_ERROR_CODE"] = "0x1771";
    JUPITER_ERROR_PATTERNS["UNDER_MINIMUM_AMOUNT"] = "RequireGteViolated";
    JUPITER_ERROR_PATTERNS["CONSUMED_MORE_FEES"] = "exceeded CUs meter";
})(JUPITER_ERROR_PATTERNS || (exports.JUPITER_ERROR_PATTERNS = JUPITER_ERROR_PATTERNS = {}));
var JUPITER_ERROR_NAME;
(function (JUPITER_ERROR_NAME) {
    JUPITER_ERROR_NAME["SLIPPAGE_TOLERANCE_EXCEEDED"] = "SlippageToleranceExceeded";
    JUPITER_ERROR_NAME["UNDER_MINIMUM_AMOUNT"] = "UnderMinimumAmount";
    JUPITER_ERROR_NAME["CONSUMED_MORE_FEES"] = "ConsumedMoreFees";
})(JUPITER_ERROR_NAME || (exports.JUPITER_ERROR_NAME = JUPITER_ERROR_NAME = {}));
exports.JUPITER_ERRORS = {
    [JUPITER_ERROR_PATTERNS.SLIPPAGE_TOLERANCE_EXCEEDED]: JUPITER_ERROR_NAME.SLIPPAGE_TOLERANCE_EXCEEDED,
    [JUPITER_ERROR_PATTERNS.JUPITER_SLIPPAGE_ERROR_CODE]: JUPITER_ERROR_NAME.SLIPPAGE_TOLERANCE_EXCEEDED,
    [JUPITER_ERROR_PATTERNS.UNDER_MINIMUM_AMOUNT]: JUPITER_ERROR_NAME.UNDER_MINIMUM_AMOUNT,
    [JUPITER_ERROR_PATTERNS.CONSUMED_MORE_FEES]: JUPITER_ERROR_NAME.CONSUMED_MORE_FEES,
};
