"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTradeQuote = exports.getTrade = void 0;
const sdk_1 = require("@lifi/sdk");
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const constants_1 = require("../../../constants");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const configureLiFi_1 = require("../utils/configureLiFi");
const constants_2 = require("../utils/constants");
const getIntermediaryTransactionOutputs_1 = require("../utils/getIntermediaryTransactionOutputs/getIntermediaryTransactionOutputs");
const getLifiEvmAssetAddress_1 = require("../utils/getLifiEvmAssetAddress/getLifiEvmAssetAddress");
const getNetworkFeeCryptoBaseUnit_1 = require("../utils/getNetworkFeeCryptoBaseUnit/getNetworkFeeCryptoBaseUnit");
const lifiTokenToAsset_1 = require("../utils/lifiTokenToAsset/lifiTokenToAsset");
const transformLifiFeeData_1 = require("../utils/transformLifiFeeData/transformLifiFeeData");
async function getTrade({ input, deps, lifiChainMap, }) {
    const { sellAsset, buyAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit, sendAddress, receiveAddress, accountNumber, supportsEIP1559, affiliateBps, potentialAffiliateBps, quoteOrRate, } = input;
    const slippageTolerancePercentageDecimal = input.slippageTolerancePercentageDecimal ??
        (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.LIFI);
    const sellLifiChainKey = lifiChainMap.get(sellAsset.chainId);
    const buyLifiChainKey = lifiChainMap.get(buyAsset.chainId);
    if (sellLifiChainKey === undefined) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `asset '${sellAsset.name}' on chainId '${sellAsset.chainId}' not supported`,
            code: types_1.TradeQuoteError.UnsupportedTradePair,
        }));
    }
    if (buyLifiChainKey === undefined) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `asset '${buyAsset.name}' on chainId '${buyAsset.chainId}' not supported`,
            code: types_1.TradeQuoteError.UnsupportedTradePair,
        }));
    }
    (0, configureLiFi_1.configureLiFi)();
    const lifiAllowedBridges = quoteOrRate === 'quote' ? input.originalRate.lifiTools?.bridges : undefined;
    const lifiAllowedExchanges = quoteOrRate === 'quote' ? input.originalRate.lifiTools?.exchanges : undefined;
    const affiliateBpsDecimalPercentage = (0, utils_1.convertBasisPointsToDecimalPercentage)(affiliateBps);
    const routesRequest = {
        fromChainId: Number((0, caip_1.fromChainId)(sellAsset.chainId).chainReference),
        toChainId: Number((0, caip_1.fromChainId)(buyAsset.chainId).chainReference),
        fromTokenAddress: (0, getLifiEvmAssetAddress_1.getLifiEvmAssetAddress)(sellAsset),
        toTokenAddress: (0, getLifiEvmAssetAddress_1.getLifiEvmAssetAddress)(buyAsset),
        fromAddress: sendAddress,
        toAddress: receiveAddress,
        fromAmount: sellAmountIncludingProtocolFeesCryptoBaseUnit,
        options: {
            // used for analytics and affiliate fee - do not change this without considering impact
            integrator: constants_2.LIFI_INTEGRATOR_ID,
            slippage: Number(slippageTolerancePercentageDecimal),
            // Routes via Stargate or Amarok can always be executed in one step, as LiFi can make those
            // bridges swap into any requested token on the destination chain. Other bridges my require
            // two steps. As such, additional balance checks on the destination chain are required which
            // are currently incompatible with our fee calculations, leading to incorrect fee display,
            // reverts, partial swaps, wrong received tokens (due to out-of-gas mid-trade), etc. For now,
            // these bridges are disabled.
            bridges: {
                deny: ['stargate', 'stargateV2', 'stargateV2Bus', 'amarok', 'arbitrum'],
                ...(lifiAllowedBridges ? { allow: lifiAllowedBridges } : {}),
            },
            ...(lifiAllowedExchanges
                ? {
                    exchanges: { allow: lifiAllowedExchanges },
                }
                : {}),
            allowSwitchChain: true,
            fee: affiliateBpsDecimalPercentage.isZero()
                ? undefined
                : affiliateBpsDecimalPercentage.toNumber(),
        },
    };
    // getMixPanel()?.track(MixPanelEvent.SwapperApiRequest, {
    //   swapper: SwapperName.LIFI,
    //   method: 'get',
    //   // Note, this may change if the Li.Fi SDK changes
    //   url: 'https://li.quest/v1/advanced/routes',
    // })
    const routesResponse = await (0, sdk_1.getRoutes)(routesRequest)
        .then(response => (0, monads_1.Ok)(response))
        .catch((e) => {
        // This shouldn't happen, but if it does (`/routes`) endpoint errors), Li.Fi probably went "down" for that specific request
        // We should use the stale rate quote, though if it's a proper limit, `/stepTransaction` would fail too, meaning things will still fail at Tx execution time
        // Which is much better than a blank screen
        if (quoteOrRate === 'quote')
            return (0, monads_1.Ok)({ routes: [] });
        // This is a rate. All errors re: validation or internal server errors etc should be handled gracefully
        const code = (() => {
            switch (e.code) {
                case sdk_1.LiFiErrorCode.ValidationError:
                    // our input was incorrect - the error is internal to us
                    return types_1.TradeQuoteError.InternalError;
                case sdk_1.LiFiErrorCode.InternalError:
                case sdk_1.LiFiErrorCode.Timeout:
                default:
                    return types_1.TradeQuoteError.QueryFailed;
            }
        })();
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: e.message,
            code,
        }));
    });
    if (routesResponse.isErr())
        return (0, monads_1.Err)(routesResponse.unwrapErr());
    const { routes } = routesResponse.unwrap();
    if (routes.length === 0) {
        if (quoteOrRate === 'quote')
            return (0, monads_1.Ok)([
                {
                    ...input.originalRate,
                    quoteOrRate: 'quote',
                },
            ]);
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'no route found',
            code: types_1.TradeQuoteError.NoRouteFound,
        }));
    }
    const promises = await Promise.allSettled(routes.slice(0, 3).map(async (selectedLifiRoute) => {
        // this corresponds to a "hop", so we could map the below code over selectedLifiRoute.steps to
        // generate a multi-hop quote
        const steps = (await Promise.all(selectedLifiRoute.steps.map(async (lifiStep) => {
            const stepSellAsset = (0, lifiTokenToAsset_1.lifiTokenToAsset)(lifiStep.action.fromToken, deps.assetsById);
            const stepChainId = stepSellAsset.chainId;
            const stepBuyAsset = (0, lifiTokenToAsset_1.lifiTokenToAsset)(lifiStep.action.toToken, deps.assetsById);
            // for the rate to be valid, both amounts must be converted to the same precision
            const estimateRate = (0, utils_1.convertPrecision)({
                value: lifiStep.estimate.toAmount,
                inputExponent: stepBuyAsset.precision,
                outputExponent: stepSellAsset.precision,
            })
                .dividedBy((0, utils_1.bn)(lifiStep.estimate.fromAmount))
                .toFixed();
            const protocolFees = (0, transformLifiFeeData_1.transformLifiStepFeeData)({
                chainId: stepChainId,
                lifiStep,
                assets: deps.assetsById,
            });
            const sellAssetProtocolFee = protocolFees[stepSellAsset.assetId];
            const buyAssetProtocolFee = protocolFees[stepBuyAsset.assetId];
            const sellSideProtocolFeeCryptoBaseUnit = (0, utils_1.bnOrZero)(sellAssetProtocolFee?.amountCryptoBaseUnit);
            const sellSideProtocolFeeBuyAssetBaseUnit = (0, utils_1.bnOrZero)((0, utils_1.convertPrecision)({
                value: sellSideProtocolFeeCryptoBaseUnit,
                inputExponent: stepSellAsset.precision,
                outputExponent: stepBuyAsset.precision,
            })).times(estimateRate);
            const buySideProtocolFeeCryptoBaseUnit = (0, utils_1.bnOrZero)(buyAssetProtocolFee?.amountCryptoBaseUnit);
            const buyAmountAfterFeesCryptoBaseUnit = (0, utils_1.bnOrZero)(lifiStep.estimate.toAmount).toPrecision();
            const buyAmountBeforeFeesCryptoBaseUnit = (0, utils_1.bnOrZero)(buyAmountAfterFeesCryptoBaseUnit)
                .plus(sellSideProtocolFeeBuyAssetBaseUnit)
                .plus(buySideProtocolFeeCryptoBaseUnit)
                .toFixed(0);
            const sellAmountIncludingProtocolFeesCryptoBaseUnit = lifiStep.action.fromAmount;
            const intermediaryTransactionOutputs = (0, getIntermediaryTransactionOutputs_1.getIntermediaryTransactionOutputs)(deps.assetsById, lifiStep);
            const networkFeeCryptoBaseUnit = await (0, getNetworkFeeCryptoBaseUnit_1.getNetworkFeeCryptoBaseUnit)({
                chainId: stepChainId,
                lifiStep,
                supportsEIP1559: Boolean(supportsEIP1559),
                deps,
                from: sendAddress,
            });
            const source = `${types_1.SwapperName.LIFI} • ${lifiStep.toolDetails.name}`;
            return {
                allowanceContract: lifiStep.estimate.approvalAddress,
                accountNumber,
                buyAmountBeforeFeesCryptoBaseUnit,
                buyAmountAfterFeesCryptoBaseUnit,
                buyAsset: stepBuyAsset,
                intermediaryTransactionOutputs,
                feeData: {
                    protocolFees,
                    networkFeeCryptoBaseUnit,
                },
                rate: estimateRate,
                sellAmountIncludingProtocolFeesCryptoBaseUnit,
                sellAsset: stepSellAsset,
                source,
                estimatedExecutionTimeMs: 1000 * lifiStep.estimate.executionDuration,
            };
        })));
        // The rate for the entire multi-hop swap
        const netRate = (0, utils_1.convertPrecision)({
            value: selectedLifiRoute.toAmountMin,
            inputExponent: buyAsset.precision,
            outputExponent: sellAsset.precision,
        })
            .dividedBy((0, utils_1.bn)(selectedLifiRoute.fromAmount))
            .toString();
        const lifiBridgeTools = selectedLifiRoute.steps
            .filter(current => current.includedSteps?.some(includedStep => includedStep.type === 'cross'))
            .map(current => current.tool);
        const lifiExchangeTools = selectedLifiRoute.steps
            .filter(current => 
        // A step tool is an exchange (swap) tool if all of its steps are non-cross-chain steps
        current.includedSteps?.every(includedStep => includedStep.type !== 'cross'))
            .map(current => current.tool);
        return {
            id: selectedLifiRoute.id,
            receiveAddress,
            quoteOrRate,
            lifiTools: {
                bridges: lifiBridgeTools.length ? lifiBridgeTools : undefined,
                exchanges: lifiExchangeTools.length ? lifiExchangeTools : undefined,
            },
            affiliateBps,
            potentialAffiliateBps,
            steps,
            rate: netRate,
            selectedLifiRoute,
            slippageTolerancePercentageDecimal,
        };
    }));
    if (promises.every(utils_1.isRejected)) {
        for (const promise of promises) {
            if (promise.reason instanceof sdk_1.SDKError) {
                if (promise.reason.stack?.includes('Request failed with status code 429')) {
                    return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
                        message: `[LiFi: tradeQuote] - ${promise.reason.message}`,
                        code: types_1.TradeQuoteError.RateLimitExceeded,
                    }));
                }
                return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
                    message: `[LiFi: tradeQuote] - ${promise.reason.message}`,
                    code: types_1.TradeQuoteError.QueryFailed,
                }));
            }
        }
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: '[LiFi: tradeQuote] - failed to get fee data',
            code: types_1.TradeQuoteError.NetworkFeeEstimationFailed,
        }));
    }
    return (0, monads_1.Ok)(promises.filter(utils_1.isFulfilled).map(({ value }) => value));
}
exports.getTrade = getTrade;
const getTradeQuote = async (input, deps, lifiChainMap) => {
    const quotesResult = await getTrade({ input, deps, lifiChainMap });
    return quotesResult.map(quotes => quotes.map(quote => ({
        ...quote,
        quoteOrRate: 'quote',
        receiveAddress: quote.receiveAddress,
        steps: quote.steps.map(step => step),
    })));
};
exports.getTradeQuote = getTradeQuote;
