"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCallDataFromQuote = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("@shapeshiftoss/utils");
const viem_1 = require("viem");
const getThorTxData_1 = require("../evm/utils/getThorTxData");
const longTailHelpers_1 = require("./longTailHelpers");
const getCallDataFromQuote = async ({ tradeType, data, sellAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit, memo: tcMemo, expiry, config, longtailData, slippageTolerancePercentageDecimal, router, vault, }) => {
    switch (tradeType) {
        case longTailHelpers_1.TradeType.L1ToL1: {
            return data;
        }
        case longTailHelpers_1.TradeType.LongTailToL1: {
            const expectedAmountOut = longtailData?.longtailToL1ExpectedAmountOut ?? '0';
            const amountOutMin = BigInt((0, utils_1.bnOrZero)(expectedAmountOut)
                .times((0, utils_1.bn)(1).minus(slippageTolerancePercentageDecimal ?? 0))
                .toFixed(0, utils_1.BigNumber.ROUND_UP));
            const swapInAbiItem = (0, viem_1.parseAbiItem)('function swapIn(address tcRouter, address tcVault, string tcMemo, address token, uint256 amount, uint256 amountOutMin, uint256 deadline)');
            const tcRouter = router;
            const tcVault = vault;
            const token = (0, caip_1.fromAssetId)(sellAsset.assetId).assetReference;
            const amount = BigInt(sellAmountIncludingProtocolFeesCryptoBaseUnit);
            const currentTimestamp = BigInt(Math.floor(Date.now() / 1000));
            const tenMinutes = BigInt(600);
            const deadline = currentTimestamp + tenMinutes;
            const params = [tcRouter, tcVault, tcMemo, token, amount, amountOutMin, deadline];
            const swapInData = (0, viem_1.encodeFunctionData)({
                abi: [swapInAbiItem],
                functionName: 'swapIn',
                args: params,
            });
            return swapInData;
        }
        case longTailHelpers_1.TradeType.L1ToLongTail: {
            const { data: dataWithAmountOut } = await (0, getThorTxData_1.getThorTxInfo)({
                sellAsset,
                sellAmountCryptoBaseUnit: sellAmountIncludingProtocolFeesCryptoBaseUnit,
                memo: tcMemo,
                expiry,
                config,
            });
            return dataWithAmountOut;
        }
        case longTailHelpers_1.TradeType.LongTailToLongTail:
            throw Error(`Unsupported trade type: ${longTailHelpers_1.TradeType}`);
        default:
            return (0, utils_1.assertUnreachable)(tradeType);
    }
};
exports.getCallDataFromQuote = getCallDataFromQuote;
