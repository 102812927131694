"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unfreeze = void 0;
/**
 * Creates a shallow clone to allow mutation of a readonly object
 */
const unfreeze = (obj) => {
    return { ...obj };
};
exports.unfreeze = unfreeze;
