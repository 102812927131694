"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jupiterApi = void 0;
const types_1 = require("../../types");
const utils_1 = require("../../utils");
const getTradeQuote_1 = require("./swapperApi/getTradeQuote");
const getTradeRate_1 = require("./swapperApi/getTradeRate");
exports.jupiterApi = {
    getTradeRate: getTradeRate_1.getTradeRate,
    getTradeQuote: getTradeQuote_1.getTradeQuote,
    getUnsignedSolanaTransaction: async ({ tradeQuote, from, assertGetSolanaChainAdapter, }) => {
        if (!(0, utils_1.isExecutableTradeQuote)(tradeQuote))
            throw Error('Unable to execute trade');
        const step = tradeQuote.steps[0];
        const adapter = assertGetSolanaChainAdapter(step.sellAsset.chainId);
        if (!(0, utils_1.isExecutableTradeStep)(step))
            throw Error('Unable to execute step');
        const solanaInstructions = step.jupiterTransactionMetadata?.instructions?.map(instruction => adapter.convertInstruction(instruction));
        if (!(0, types_1.isSolanaFeeData)(step.feeData.chainSpecific))
            throw Error('Unable to execute step');
        const buildSwapTxInput = {
            to: '',
            from,
            value: '0',
            accountNumber: step.accountNumber,
            chainSpecific: {
                addressLookupTableAccounts: step.jupiterTransactionMetadata?.addressLookupTableAddresses,
                instructions: solanaInstructions,
                computeUnitLimit: step.feeData.chainSpecific?.computeUnits,
                computeUnitPrice: step.feeData.chainSpecific?.priorityFee,
            },
        };
        return (await adapter.buildSendApiTransaction(buildSwapTxInput)).txToSign;
    },
    getSolanaTransactionFees: async ({ tradeQuote, from, assertGetSolanaChainAdapter, }) => {
        if (!(0, utils_1.isExecutableTradeQuote)(tradeQuote))
            throw Error('Unable to execute trade');
        const step = tradeQuote.steps[0];
        const adapter = assertGetSolanaChainAdapter(step.sellAsset.chainId);
        if (!(0, utils_1.isExecutableTradeStep)(step))
            throw Error('Unable to execute step');
        const solanaInstructions = step.jupiterTransactionMetadata?.instructions?.map(instruction => adapter.convertInstruction(instruction));
        if (!(0, types_1.isSolanaFeeData)(step.feeData.chainSpecific))
            throw Error('Unable to execute step');
        const buildSwapTxInput = {
            to: '',
            from,
            value: '0',
            accountNumber: step.accountNumber,
            chainSpecific: {
                addressLookupTableAccounts: step.jupiterTransactionMetadata?.addressLookupTableAddresses,
                instructions: solanaInstructions,
                computeUnitLimit: step.feeData.chainSpecific?.computeUnits,
                computeUnitPrice: step.feeData.chainSpecific?.priorityFee,
            },
        };
        const { txToSign } = await adapter.buildSendApiTransaction(buildSwapTxInput);
        const getFeeDataInput = {
            to: txToSign.to,
            value: txToSign.value,
            chainSpecific: {
                from,
                addressLookupTableAccounts: step.jupiterTransactionMetadata?.addressLookupTableAddresses,
                instructions: txToSign.instructions,
            },
        };
        const feeData = await adapter.getFeeData(getFeeDataInput);
        return feeData.fast.txFee;
    },
    checkTradeStatus: utils_1.checkSolanaSwapStatus,
};
