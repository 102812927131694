"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMaxBytesLengthByChainId = exports.BCH_MAXIMUM_BYTES_LENGTH = exports.BTC_MAXIMUM_BYTES_LENGTH = exports.THORCHAIN_OUTBOUND_FEE_RUNE_THOR_UNIT = exports.THORCHAIN_LONGTAIL_STREAMING_SWAP_SOURCE = exports.THORCHAIN_LONGTAIL_SWAP_SOURCE = exports.THORCHAIN_STREAM_SWAP_SOURCE = exports.THORCHAIN_SUPPORTED_CHAIN_IDS = exports.buySupportedChainIds = exports.sellSupportedChainIds = exports.THOR_PRECISION = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const types_1 = require("@shapeshiftoss/types");
const utils_1 = require("@shapeshiftoss/utils");
const types_2 = require("../../types");
exports.THOR_PRECISION = 8;
exports.sellSupportedChainIds = {
    [types_1.KnownChainIds.EthereumMainnet]: true,
    [types_1.KnownChainIds.BitcoinMainnet]: true,
    [types_1.KnownChainIds.DogecoinMainnet]: true,
    [types_1.KnownChainIds.LitecoinMainnet]: true,
    [types_1.KnownChainIds.BitcoinCashMainnet]: true,
    [types_1.KnownChainIds.CosmosMainnet]: true,
    [types_1.KnownChainIds.ThorchainMainnet]: true,
    [types_1.KnownChainIds.AvalancheMainnet]: true,
    [types_1.KnownChainIds.BnbSmartChainMainnet]: true,
    [types_1.KnownChainIds.BaseMainnet]: true,
};
exports.buySupportedChainIds = {
    [types_1.KnownChainIds.EthereumMainnet]: true,
    [types_1.KnownChainIds.BitcoinMainnet]: true,
    [types_1.KnownChainIds.DogecoinMainnet]: true,
    [types_1.KnownChainIds.LitecoinMainnet]: true,
    [types_1.KnownChainIds.BitcoinCashMainnet]: true,
    [types_1.KnownChainIds.CosmosMainnet]: true,
    [types_1.KnownChainIds.ThorchainMainnet]: true,
    [types_1.KnownChainIds.AvalancheMainnet]: true,
    [types_1.KnownChainIds.BnbSmartChainMainnet]: true,
    [types_1.KnownChainIds.BaseMainnet]: true,
};
exports.THORCHAIN_SUPPORTED_CHAIN_IDS = {
    sell: Object.keys(exports.sellSupportedChainIds),
    buy: Object.keys(exports.buySupportedChainIds),
};
exports.THORCHAIN_STREAM_SWAP_SOURCE = `${types_2.SwapperName.Thorchain} • Streaming`;
exports.THORCHAIN_LONGTAIL_SWAP_SOURCE = `${types_2.SwapperName.Thorchain} • Long-tail`;
exports.THORCHAIN_LONGTAIL_STREAMING_SWAP_SOURCE = `${types_2.SwapperName.Thorchain} • Long-tail streaming`;
// https://dev.thorchain.org/thorchain-dev/interface-guide/fees#thorchain-native-rune
// static automatic outbound fee as defined by: https://daemon.thorchain.shapeshift.com/lcd/thorchain/constants
// expressed in thor units (8 decimals of precision)
exports.THORCHAIN_OUTBOUND_FEE_RUNE_THOR_UNIT = '2000000';
exports.BTC_MAXIMUM_BYTES_LENGTH = 80;
exports.BCH_MAXIMUM_BYTES_LENGTH = 220;
const getMaxBytesLengthByChainId = (chainId) => {
    if (chainId === caip_1.bchChainId)
        return exports.BCH_MAXIMUM_BYTES_LENGTH;
    if ((0, utils_1.isUtxoChainId)(chainId))
        return exports.BTC_MAXIMUM_BYTES_LENGTH;
    return Infinity;
};
exports.getMaxBytesLengthByChainId = getMaxBytesLengthByChainId;
